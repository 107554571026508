<template>
    <generic-base
        :id="id"
        :load-url="`/api/forms/admin/${id}/fetch`"
        record-key="form"
        :breadcrumb-items="breadcrumbs"
        @loaded="addBreadcrumb"
    ></generic-base>
</template>
<script>
import GenericBase from '@/components/GenericBase.vue';
export default {
  components: { GenericBase },
    props: ["id"],
    data() {
        return {
            breadcrumbs: [{ text: 'My Forms', to: { name: 'forms' } }]
        };
    },
    methods: {
        addBreadcrumb(record) {
            console.log(record);
            this.breadcrumbs.splice(1);
            this.breadcrumbs.push(
                { text: record.title, to: { name: 'edit_form', params: { id: record.id } } }
            );
        }
    }
}
</script>