<template>
    <div>
        <b-breadcrumb :items="[...breadcrumbItems, ...appendedCrumbs]"></b-breadcrumb>
        <!--{{ record }}-->
        <b-skeleton-wrapper class="mt-2" :loading="loading">
            <template #loading>
                <b-card v-if="spinner" class="text-center">
                    <b-spinner variant="primary"></b-spinner>
                </b-card>
                <b-card v-else>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card>
            </template>
            <router-view
                v-if="record.id"
                v-bind="{ [recordKey || 'record']: record }"
                @push-breadcrumb="pushBreadcrumb"
                @reset-breadcrumbs="resetBreadcrumbs"
                @update-record="updateRecord"
            ></router-view>
        </b-skeleton-wrapper>
    </div>
</template>
<script>
export default {
    props: ["id", "record-key", "breadcrumb-items", "load-url", "spinner"],
    data() {
        return {
            record: {},
            loading: true,
            appendedCrumbs: []
        };
    },
    mounted() {
        this.loadRecord();
    },
    methods: {
        loadRecord() {
            this.$api.get(
                this.loadUrl,
                { params: { id: this.id } }
            ).then(({ data: { record } }) => {
                console.log(record, "generic base record loaded");
                this.record  = record;
                this.loading = false;
                this.$emit("loaded", record);
            });
        },
        updateRecord(record) {
            console.log("UPDATED");
            this.record = record;
            this.resetBreadcrumbs();
            this.$emit("loaded", record);
        },
        pushBreadcrumb(breadcrumb) {
            this.appendedCrumbs.push(breadcrumb);
        },
        resetBreadcrumbs() {
            this.appendedCrumbs = [];
        }
    },
    computed: {
        options() {
            return {
                [this.recordKey || "record"]: this.record
            };
        }
    }
}
</script>