import { render, staticRenderFns } from "./GenericBase.vue?vue&type=template&id=226b36f8&"
import script from "./GenericBase.vue?vue&type=script&lang=js&"
export * from "./GenericBase.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports